var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showKYBModal),expression:"showKYBModal"}],staticClass:"modal-medium-height",on:{"close":function($event){_vm.showKYBModal ? _vm.onCloseKYBModal() : null}}},[(_vm.KYBCompanyDetail)?_c('template',{slot:"header"},[_c('div',{staticClass:"company-header-wrapper"},[_c('i',{staticClass:"company-appended-icon",class:_vm.companyStatusClass}),_c('h2',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm.KYBCompanyDetail.details ? (_vm.KYBCompanyDetail.details.legal_name && _vm.KYBCompanyDetail.details.legal_name.toLowerCase()) || (_vm.KYBCompanyDetail.details.social_name && _vm.KYBCompanyDetail.details.social_name.toLowerCase()) : "")+" ")])])]):_vm._e(),_c('template',{slot:"body"},[_c('KYBStatusInfo',{staticClass:"modal-header-highlight",attrs:{"KYBCompanyDetail":_vm.KYBCompanyDetail,"comesFromParentKYBCompany":true},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}),_c('div',{staticClass:"body-modal"},[_c('KYBProgressTabs',{attrs:{"activeTabName":_vm.activeTabName,"KYBCompanyDetail":_vm.KYBCompanyDetail,"adminInformation":_vm.uboDetail || {}},on:{"activeTabName":function($event){_vm.activeTabName = $event},"showAndGetUBODetail":function($event){return _vm.showAndGetUBODetail(_vm.KYBCompanyDetail.admin)}}}),_c('div',{staticClass:"tabs-content-wrapper"},[(_vm.activeTabName === 'companyInfo')?_c('KYBCompanyInfo',{attrs:{"hasContact":!!_vm.KYBCompanyDetail &&
              !!_vm.KYBCompanyDetail.contact &&
              !!_vm.KYBCompanyDetail.contact.user &&
              !!_vm.KYBCompanyDetail.contact.user.email,"companyInfoDetails":_vm.KYBCompanyDetail.details,"kybCompanyId":_vm.KYBCompanyDetail.id},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e(),(_vm.activeTabName === 'companyExtraDocuments')?_c('KYBExtraDocuments',{attrs:{"extraDocuments":_vm.KYBCompanyDetail.files,"KYBCompanyDetail":_vm.KYBCompanyDetail,"loadingProp":_vm.loadingProp || _vm.loading},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e(),(_vm.activeTabName === 'representativeDetail')?_c('UserDetail',{staticClass:"user-detail",attrs:{"loadingProp":_vm.loading,"resource":_vm.uboDetail &&
              _vm.uboDetail.processing_attempts &&
              _vm.uboDetail.processing_attempts[0],"KYBCompanyPrincipals":_vm.KYBCompanyDetailPrincipals,"showResourceDetail":_vm.showUBODetail,"user":_vm.uboDetail,"isScreeningKYBCompany":_vm.KYBCompanyDetail.status === 'SCREENING'},on:{"onUpdateResource":function($event){return _vm.updateResource($event)}}}):_vm._e(),(_vm.KYBCompanyDetail.details && _vm.activeTabName === 'UBODiagram')?_c('UBODiagram',{class:{ 'is-focus-node': !!_vm.KYBUBOCompanySelected },attrs:{"KYBCompanyData":_vm.KYBCompanyDetail,"focusNode":_vm.KYBUBOCompanySelected,"countriesList":_vm.countriesList},on:{"showUBODetail":function($event){return _vm.showAndGetUBODetail($event)},"unfocus":function($event){_vm.KYBUBOCompanySelected = null}}}):_vm._e(),(_vm.activeTabName === 'proofOfAddress')?_c('KYBCPOADetail',{attrs:{"processingAttempts":_vm.KYBCompanyDetail.processing_attempts,"expectedInfo":_vm.KYBCompanyDetail.details,"kybCompanyId":_vm.KYBCompanyDetail.id},on:{"onUpdateResource":function($event){return _vm.updateResource($event)},"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e(),(_vm.activeTabName === 'sanctionsChecks')?_c('KYBSanctionsChecks',{attrs:{"sanctions":(_vm.KYBCompanyDetail.sanctions &&
                _vm.KYBCompanyDetail.sanctions.length &&
                _vm.KYBCompanyDetail.sanctions[0]) ||
              {},"kybCompanyId":_vm.KYBCompanyDetail.id},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"v-spinner",class:{ loading: _vm.loading }}):_vm._e(),(
              _vm.KYBUBOCompanySelected &&
              !_vm.loading &&
              _vm.activeTabName === 'UBODiagram'
            )?_c('div',{staticClass:"ubo-detail-wrapper"},[(_vm.uboDetail)?_c('KYBModalDetailHeader',{attrs:{"uboDetail":_vm.uboDetail}}):_vm._e(),(_vm.KYBUBOCompanySelected && _vm.uboDetail && _vm.uboDetail.details)?_c('KYBUBOCompanyDetail',{attrs:{"KYBCompanyDetail":_vm.uboDetail,"uboDetail":_vm.uboDetail,"loading":_vm.loadingProp || _vm.loading,"KYBCompanyDetailPrincipals":_vm.KYBCompanyDetailPrincipals,"countriesList":_vm.countriesList},on:{"updateResource":function($event){return _vm.updateResource($event)},"showAndGetUBODetail":function($event){return _vm.showAndGetUBODetail($event)},"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):(
                _vm.KYBUBOCompanySelected &&
                _vm.uboDetail &&
                _vm.uboDetail.verifications &&
                _vm.uboDetail.verifications.length
              )?_c('UserDetail',{staticClass:"user-detail",attrs:{"loadingProp":_vm.loading,"resource":_vm.uboDetail.verifications[0],"KYBCompanyPrincipals":_vm.KYBCompanyDetailPrincipals,"showResourceDetail":_vm.showUBODetail,"user":_vm.uboDetail,"isScreeningKYBCompany":_vm.uboDetail.status === 'SCREENING'},on:{"onUpdateResource":function($event){return _vm.updateResource($event)}}}):(_vm.KYBUBOCompanySelected && _vm.uboDetail)?_c('UBOBasicInfoDetail',{staticClass:"ubo-detail",attrs:{"uboData":_vm.uboDetail},on:{"getKYBCompanyDetail":function($event){return _vm.$emit('getKYBCompanyDetail', _vm.KYBCompanyDetail.id)}}}):_vm._e()],1):_vm._e()],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }