

























































import { Vue } from "vue-property-decorator";
import {
  getUniqueErrorsArrayFromCoreModules,
  toLowerCase,
} from "@/lib/parsers";
import {
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
  VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";
import OnelineVerifications from "@/components/OnelineVerifications.vue";
import PATabs from "@/components/processing-attempts/pa-tabs/PATabs.vue";
import UserStatusInfo from "@/components/users/UserStatusInfo.vue";
import PADetail from "@/components/processing-attempts/pa-detail/PADetail.vue";
import ListModalHeader from "@/components/modals/ListModalHeader.vue";
import RepresentativeStatusInfo from "../kyb/components/RepresentativeStatusInfo.vue";

export default Vue.extend({
  name: "UserDetail",
  props: {
    showResourceDetail: { required: false, type: Boolean, default: false },
    resource: { required: false, type: Object },
    user: { required: false, type: Object },
    loadingProp: { required: false, type: Boolean },
    KYBCompanyPrincipals: { required: false, type: Array },
    isScreeningKYBCompany: { required: false, type: Boolean, default: false },
  },
  components: {
    OnelineVerifications,
    ListModalHeader,
    PATabs,
    UserStatusInfo,
    PADetail,
    RepresentativeStatusInfo,
  },
  watch: {
    "resourceDetail.status"(val) {
      if (
        this.resourceDetail &&
        this.resourceDetail.status == "MANUAL_REVIEW"
      ) {
        this.resourceDetail.status = "PENDING";
      }
    },
    showResourceDetail: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
    user: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
    resource: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      dashboardUrl: process.env.VUE_APP_SILT_DASHBOARD_URL,
      resourceDetail: { ocr_data: {} } as any,
      palink: "",
      VerificationsMap: VerificationsMap,
      VERIFICATIONS_TYPES_MAP: VERIFICATIONS_TYPES_MAP,
      VERIFICATIONS_TYPES_VIEW_MAP: VERIFICATIONS_TYPES_VIEW_MAP,
      loading: false,
      editing: false,
      loadingRerun: false,
      statusOptions: [
        { value: "SUCCESS" },
        { value: "ERROR" },
        { value: "PENDING" },
      ],

      companyAppSelectedPermissions:
        this.$store.state.verificationFlow.permissions,
      checksRequirements: [] as any[],
      resourcesRequirements: [] as any[],
      resourcesProcessingAttempts: [] as any[],
      checksProcessingAttempts: [] as any[],
      processingAttempts: {} as any,
      notRequiredUserPAs: [] as any,
      selectedVerificationFlowType:
        this.$store.state.verificationFlow.flow_type,
    };
  },
  methods: {
    async handleResourceDetailChange(val: any) {
      if (val) {
        this.loading = true;
        this.processProcessingAttempts();
        await this.getResourceDetail();
        this.palink = window.location.href;
        this.loading = false;
      }
    },
    updateResource(event: Event) {
      this.$emit("onUpdateResource", event);
    },

    getCompanyAppSelectedPermissions() {
      this.companyAppSelectedPermissions = [];
      this.notRequiredUserPAs = [];
      if (!this.$store.state.company) return;
      if (this.user.processing_attempts && this.user.processing_attempts.length)
        for (const processingAttempt of this.user.processing_attempts) {
          let permissionType = processingAttempt.permission_type;

          if (
            ["RULE", "MISCONDUCT", "AML", "PEP", "REPRESENTATIVE"].includes(
              permissionType
            )
          )
            permissionType = permissionType + ":background";
          this.checkAndAddCompanyPermissions(
            permissionType,
            this.companyAppSelectedPermissions
          );
        }
    },

    checkAndAddCompanyPermissions(
      permissionType: string,
      companyAppPermissions: any[]
    ) {
      const checkPermission = (permissions: any[]) => {
        return permissions.some((item: string) =>
          item.includes(permissionType)
        );
      };

      const exists = checkPermission(companyAppPermissions);
      const existsInNotRequiredUserPAs = checkPermission(
        this.notRequiredUserPAs
      );

      if (!exists && !existsInNotRequiredUserPAs) {
        this.notRequiredUserPAs.push([permissionType]);
      }
    },

    getFilteredPermissions(companyAppPermissions: any) {
      const substringCondition = ":background";
      for (const permissionGroup of companyAppPermissions) {
        if (permissionGroup && permissionGroup.length) {
          for (const permission of permissionGroup) {
            if (permission && permission.includes(substringCondition)) {
              this.checksRequirements.push(
                permission.substring(0, permission.indexOf(":"))
              );
            } else {
              this.resourcesRequirements.push(permission);
            }
          }
        }
      }
    },

    splitPAtoResourcesAndChecks(processingAttempts: any) {
      const auxChecksPas = [];
      const auxResourcesPas = [];
      for (const processingAttempt of processingAttempts) {
        if (
          this.resourcesRequirements.includes(
            processingAttempt.permission_type || processingAttempt.type
          )
        ) {
          auxResourcesPas.push(processingAttempt);
        } else {
          auxChecksPas.push(processingAttempt);
        }
      }
      this.resourcesProcessingAttempts = auxResourcesPas;
      this.checksProcessingAttempts = auxChecksPas;
    },

    processProcessingAttempts() {
      if (!this.user || !this.user.processing_attempts) {
        return [];
      }
      this.processingAttempts = this.user.processing_attempts.filter(
        (attempt: any) =>
          VerificationsMap[
            VERIFICATIONS_TYPES_MAP[attempt.permission_type || attempt.type]
          ]
      );
      this.checksRequirements = [];
      this.resourcesRequirements = [];
      this.resourcesProcessingAttempts = [];
      this.checksProcessingAttempts = [];
      this.getCompanyAppSelectedPermissions();
      this.getFilteredPermissions(
        this.notRequiredUserPAs.length
          ? this.companyAppSelectedPermissions.concat(this.notRequiredUserPAs)
          : this.companyAppSelectedPermissions
      );
    },

    getUniqueErrorsArrayFromCoreModules(cm_exec: Array<string>) {
      return getUniqueErrorsArrayFromCoreModules(cm_exec);
    },

    // TODO: Delete after backend unifies naming for surname and last_name
    cleanOCRData(ocr_data: any) {
      if (!ocr_data) return ocr_data;
      if (ocr_data.name) ocr_data.first_name = ocr_data.name;
      if (ocr_data.surname) ocr_data.last_name = ocr_data.surname;
      if (ocr_data.number) ocr_data.document_number = ocr_data.number;
    },

    async getResourceDetail(resourceClicked?: any) {
      let sourceResource = resourceClicked || this.resource;
      if (
        sourceResource &&
        this.resourceDetail &&
        ((sourceResource.id && sourceResource.id == this.resourceDetail.id) ||
          (sourceResource.processing_attempt_id &&
            sourceResource.processing_attempt_id ==
              this.resourceDetail.processing_attempt_id))
      ) {
        return;
      }
      this.resourceDetail = sourceResource;
      // FETCH pictures of resource
      if (this.resourceDetail) {
        const cmes =
          this.resourceDetail.cmes ||
          this.resourceDetail.core_module_executions;
        if (cmes && cmes.length) {
          for (const cm_exec of cmes) {
            if (cm_exec.type === "OCR") {
              this.resourceDetail.ocr_data = cm_exec.output || {};
              this.resourceDetail.ocr_raw = cm_exec.raw || {};
              this.resourceDetail.ocr_status = cm_exec.status;
            }
            if (cm_exec.type === "FACE_MATCHER") {
              this.resourceDetail.verification_status = cm_exec.status;
            }
          }
          this.cleanOCRData(this.resourceDetail.ocr_data);
          this.resourceDetail.errors =
            this.getUniqueErrorsArrayFromCoreModules(cmes);
        }
        this.resourceDetail = toLowerCase(this.resourceDetail, {
          include: ["name", "address", "city", "province"],
        });
        this.resourceDetail.owner_user = this.user;
      }
    },
  },
});
