























































































































































import Vue from "vue";
import { fieldOptions, tableValues } from "../../../models/cdp/cdpModels";
import PADetailCDPInput from "../PADetailCDPInput.vue";

export default Vue.extend({
  name: "PADetailCDPTableGeneric",
  components: { PADetailCDPInput },
  props: {
    tableValues: {
      required: true,
      type: Object as () => tableValues,
    },
    fieldOptions: { required: true, type: Object as () => fieldOptions },
    addTableRowCopy: { required: false, type: String },
    inputPopoverPlacement: { required: true, type: String },
    colspan: { required: false, default: 1, type: Number },
  },
  data() {
    return {
      draggedRowIndex: null as null | number,
    };
  },
  computed: {
    numberSumTotals(): any {
      if (!this.tableValues.rows?.length) return null;
      const totalRows = this.initializeTotalRows();
      for (const row of this.tableValues.rows) {
        for (const key in row) {
          const cell = row[key] as any;
          if (this.shouldSumCell(cell, key)) {
            totalRows[key] += cell.value;
          }
        }
      }

      return totalRows;
    },
  },
  methods: {
    initializeTotalRows() {
      const totalRows = {} as any;
      if (this.tableValues.rows.length > 0) {
        for (const key in this.tableValues.rows[0]) {
          totalRows[key] = null;
        }
      } else return null;
      return totalRows;
    },
    shouldSumCell(cell: any, key: string): boolean {
      return (
        cell.value && this.isRowKeyANumber(cell.key) && !key.includes("percent")
      );
    },
    onDragStart(event: DragEvent, index: number) {
      this.draggedRowIndex = index;
      event.dataTransfer!.effectAllowed = "move";
      event.dataTransfer!.setData("text/plain", index.toString());
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
      event.dataTransfer!.dropEffect = "move";
    },
    onDrop(event: DragEvent, index: number) {
      event.preventDefault();
      const draggedIndex = this.draggedRowIndex;
      if (draggedIndex !== null && draggedIndex !== index) {
        const draggedRow = this.tableValues.rows.splice(draggedIndex, 1)[0];
        this.tableValues.rows.splice(index, 0, draggedRow);

        this.tableValues.rows.forEach((row, idx) => {
          const nextRow = this.tableValues.rows[idx + 1];
          if (this.hasIdAndIdNext(row)) {
            if (this.hasIdAndIdNext(nextRow)) {
              if (idx < this.tableValues.rows.length - 1) {
                row.id_next.value = nextRow.id;
              }
            } else {
              row.id_next.value = null;
            }
          }
        });
      }
      this.draggedRowIndex = null;
    },
    onDragEnd() {
      this.draggedRowIndex = null;
    },
    hasIdAndIdNext(
      row: any
    ): row is { id: string; id_next: { value: string | null } } {
      return (
        row &&
        typeof row.id === "string" &&
        row.id_next &&
        (typeof row.id_next.value === "string" || row.id_next.value === null)
      );
    },
    isRowKeyANumber(key: string) {
      if (
        [
          "id",
          "id_next",
          "bill_of_landing_reference",
          "identifier",
          "product_description",
          "product_name",
          "uom_description",
          "uom",
          "percent",
          "amount",
        ].includes(key)
      )
        return false;
      return true;
    },
  },
});
