










































































































































import "./VTable.flexbox.styl";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { HTTP } from "@/lib/http/api";
import VTableSkeleton from "@/components/skeletons/components/VTableSkeleton.vue";

export interface VTableHeader {
  name: string;
  text: string;
  class?: string;
  style?: string;
  sortable?: boolean;
  ascending?: boolean;
  disabled?: boolean;
}

export type VTableInstance = InstanceType<typeof VTable>;

@Component({
  components: { VTableSkeleton },
})
export default class VTable extends Vue {
  @Prop()
  items?: object[];
  @Prop()
  headers?: VTableHeader[];
  @Prop()
  getRowsData?: (params: URLSearchParams) => {
    results: any[];
    count: number;
    next: string;
  };
  @Prop({ type: Boolean })
  hideFilters?: boolean;
  @Prop({ type: Boolean })
  hidePagination?: boolean;
  @Prop({ type: Boolean })
  hideDetails?: boolean;
  @Prop({ type: Boolean, default: false })
  showSearchField?: boolean;
  @Prop({ type: Boolean, default: true })
  addQueryStringAtNextURL?: boolean;
  @Prop({ type: String, default: "filter_by_status" })
  filtersMultiselectHeader?: string;
  @Prop({ type: String, default: "" })
  searchAdditionalParamsPropString?: string;
  @Prop()
  syncRowsData?: object[];
  @Prop({
    type: (Object as () => { [key: string]: string }) || null,
    default: () => null,
  })
  filterOptions?: { [key: string]: string } | null;
  @Prop({ type: String })
  queryValue?: string;
  hasNext = true;
  loading = false;
  rowsData = { results: [] as any, count: 0, next: "" };
  searchParamsString = "";
  searchParams: URLSearchParams = new URLSearchParams(this.searchParamsString);
  isHeaderFixed = false;
  lastClicked = "";
  searchField = "";

  async initAndGetRows() {
    this.initRowsData();
    await this.getRows();
  }

  @Watch("searchAdditionalParamsPropString")
  @Watch("searchParamsString")
  handlerSearchParamsProp(val?: any) {
    let searchParamsString = this.searchParamsString;
    if (this.searchAdditionalParamsPropString)
      searchParamsString =
        searchParamsString + this.searchAdditionalParamsPropString;
    else searchParamsString = searchParamsString;
    this.searchParams = new URLSearchParams(searchParamsString);
    this.$router.push({ query: { filters: val } });
  }

  @Watch("searchField")
  handlerFilterChange(val: any) {
    this.searchParams.set("query", val);
    this.searchParamsString = this.searchParams.toString();
  }
  @Watch("$store.state.verificationFlow.app_id")
  @Watch("searchParams", { immediate: true })
  async handlerSearchParams() {
    await this.initAndGetRows();
  }

  @Watch("getRowsData")
  async handleGetRowsData() {
    await this.getRows();
  }

  @Watch("syncRowsData")
  onSyncRowsDataChange() {
    this.rowsData.results = this.syncRowsData;
  }

  async getNext() {
    this.loading = true;
    if (!this.rowsData.next.startsWith("https"))
      this.rowsData.next = this.rowsData.next.replace("http", "https");
    const response = (
      await HTTP.get(
        this.addQueryStringAtNextURL
          ? this.rowsData.next + "&" + this.searchParamsString
          : this.rowsData.next
      )
    ).data;
    this.rowsData.results = [...this.rowsData.results, ...response.results];
    if (response.results.length == 0) this.hasNext = false;
    this.rowsData.next = response.next;
    if (this.syncRowsData?.length)
      this.$emit(
        "updateSyncData",
        this.rowsData.results.map((item: any) => item.id)
      );
    this.loading = false;
  }

  async getRows() {
    this.loading = true;
    const searchParams = this.searchParams.toString();
    if (!this.getRowsData) return this.rowsData;
    const response = await this.getRowsData(this.searchParams);
    if (!response) return;
    if (searchParams == this.searchParams.toString()) {
      this.rowsData.results = [...response.results];
      this.rowsData.next = response.next;
      this.$emit("query", this.searchParams);
    }
    this.loading = false;
    this.$emit("gotNewData", this.rowsData.results);
  }

  get sortDescending() {
    return (
      !this.searchParams.get("descending") ||
      this.searchParams.get("descending") === "false"
    );
  }

  sortColumn(column: string) {
    if (this.searchParams.get("sortBy") === column) {
      this.searchParams.set(
        "descending",
        this.sortDescending ? "true" : "false"
      );
    } else {
      this.searchParams.set("sortBy", column);
      this.searchParams.set("descending", "true");
    }
    this.searchParamsString = this.searchParams.toString();
  }

  initRowsData() {
    this.rowsData = { results: [] as any, count: 0, next: "" };
  }

  onRowClick(item: any) {
    if (item.id) this.lastClicked = item.id;
    this.$emit("onRowClicked", item);
    this.$set(
      item,
      "details_active",
      !item.details_active && !this.hideDetails
    );
  }
}
